import React, { useState, useCallback } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { Global, css } from '@emotion/core';
import Gallery from 'react-photo-gallery';
import Imgix, { Background } from 'react-imgix';
import Carousel, { Modal, ModalGateway } from 'react-images';
import debounce from 'debounce';

import { trackEvent } from '../tracking';
import { BREAKPOINTS, unit } from '../theme';
import Layout from '../components/layout';
import Section from '../components/Section';
import SectionHeader from '../components/SectionHeader';

const BASE_URL = 'https://jkandlisa.s3-eu-west-1.amazonaws.com/london-ax9azu/';
const IMGIX_URL = 'https://jkandlisa.imgix.net/london-ax9azu/';

const PHOTOS = [
  { src: '20190803-JK&Lisa-02.jpg', width: 7952, height: 5304 },
  { src: '20190803-JK&Lisa-03.jpg', width: 5193, height: 7786 },
  { src: '20190803-JK&Lisa-04.jpg', width: 7333, height: 4890 },
  { src: '20190803-JK&Lisa-05.jpg', width: 7223, height: 4817 },
  { src: '20190803-JK&Lisa-06.jpg', width: 5046, height: 7566 },
  { src: '20190803-JK&Lisa-07.jpg', width: 7722, height: 5150 },
  { src: '20190803-JK&Lisa-08.jpg', width: 7591, height: 5063 },
  { src: '20190803-JK&Lisa-09.jpg', width: 6486, height: 4326 },
  { src: '20190803-JK&Lisa-10.jpg', width: 4470, height: 6700 },
  { src: '20190803-JK&Lisa-11.jpg', width: 7744, height: 5166 },
  { src: '20190803-JK&Lisa-12.jpg', width: 7826, height: 5220 },
  { src: '20190803-JK&Lisa-13.jpg', width: 7774, height: 5185 },
  { src: '20190803-JK&Lisa-14.jpg', width: 7506, height: 5007 },
  { src: '20190803-JK&Lisa-15.jpg', width: 7221, height: 4817 },
  { src: '20190803-JK&Lisa-16.jpg', width: 7664, height: 5112 },
  { src: '20190803-JK&Lisa-17.jpg', width: 3501, height: 5252 },
  { src: '20190803-JK&Lisa-18.jpg', width: 5530, height: 3687 },
  { src: '20190803-JK&Lisa-19.jpg', width: 6756, height: 4506 },
  { src: '20190803-JK&Lisa-20.jpg', width: 4870, height: 3247 },
  { src: '20190803-JK&Lisa-21.jpg', width: 5219, height: 3480 },
  { src: '20190803-JK&Lisa-22.jpg', width: 5617, height: 3745 },
  { src: '20190803-JK&Lisa-23.jpg', width: 5306, height: 3538 },
  { src: '20190803-JK&Lisa-24.jpg', width: 5357, height: 3570 },
  { src: '20190803-JK&Lisa-25.jpg', width: 5153, height: 3435 },
  { src: '20190803-JK&Lisa-26.jpg', width: 5760, height: 3840 },
  { src: '20190803-JK&Lisa-27.jpg', width: 5760, height: 3840 },
  { src: '20190803-JK&Lisa-28.jpg', width: 4633, height: 3089 },
  { src: '20190803-JK&Lisa-29.jpg', width: 4488, height: 3293 },
  { src: '20190803-JK&Lisa-30.jpg', width: 4581, height: 3054 },
  { src: '20190803-JK&Lisa-31.jpg', width: 5306, height: 3537 },
];

function getImageIndex(src) {
  const index = PHOTOS.findIndex(photo => photo.src === src);
  return index < 0 ? null : index;
}

function getPlaceholderSize(photo) {
  let width, height;
  if (photo.width > photo.height) {
    width = 800;
    height = (photo.height / photo.width) * width;
  } else {
    height = 800;
    width = (photo.width / photo.height) * height;
  }

  return [width, height];
}

function PhotoViewer(props) {
  const { getStyles, data } = props;
  const [width, height] = getPlaceholderSize(data);
  return (
    <div css={getStyles('view', props)}>
      <Imgix
        className="lazyload"
        src={`${IMGIX_URL}${data.src}`}
        sizes="100vw"
        attributeConfig={{
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        }}
        htmlAttributes={{
          'data-lowsrc': `${IMGIX_URL}${data.src}?auto=format&fit=crop&w=${width}&h=${height}&px=16&blur=300`,
        }}
        css={{
          height: 'auto',
          maxHeight: '100vh',
          maxWidth: '100%',
          userSelect: 'none',
        }}
      />
    </div>
  );
}

function getFooterCaption(location) {
  function FooterCaption(props) {
    const { getStyles } = props;

    const styles = getStyles('footerCaption', props);
    return (
      <span
        css={{
          ...styles,
          flex: 1,
          display: 'flex',
        }}
      >
        <a
          href={`${BASE_URL}${props.currentView.src}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            trackEvent('Download image', {
              image: props.currentView.src,
              urlPath: location.pathname,
            });
          }}
          css={{
            display: 'flex',
            padding: '0 10px',
            alignItems: 'center',
            textDecoration: 'none',
            color: '#fff',
            '&:hover': {
              color: '#fff',
            },
          }}
          title="Download full size image"
        >
          <svg
            viewBox="0 0 400 500"
            css={{ fill: '#fff', height: 30, position: 'relative', top: 2 }}
          >
            <path d="M358.8 272.2v70.3a16 16 0 0 1-.5 3.9c-1.4 6.9-7.5 12.1-14.7 12.1H56.3c-7.7 0-14.1-5.9-14.9-13.4-.2-.9-.2-1.7-.2-2.7v-70.3c0-8.3 6.8-15 15-15 4.1 0 7.9 1.7 10.6 4.4 2.7 2.7 4.4 6.5 4.4 10.6v56.3h257.7v-56.3c0-8.3 6.8-15 15-15 4.1 0 7.9 1.7 10.6 4.4 2.6 2.8 4.3 6.6 4.3 10.7z" />
            <path d="M286.5 201.8l-73.7 73.7c-.1.2-.3.3-.4.4-2.7 2.7-6.2 4.4-9.7 4.9-.3 0-.6.1-.9.1-.6.1-1.2.1-1.8.1l-1.7-.1c-.3 0-.6-.1-.9-.1-3.6-.5-7-2.2-9.7-4.9l-.4-.4-73.7-73.7c-3.4-3.4-5.1-7.9-5.1-12.4s1.7-9 5.1-12.4c6.8-6.8 17.9-6.8 24.8 0l44.3 44.3V59c0-9.6 7.9-17.5 17.5-17.5 4.8 0 9.2 2 12.4 5.1 3.2 3.2 5.1 7.5 5.1 12.4v162.3L262 177c6.8-6.8 17.9-6.8 24.8 0 6.5 6.9 6.5 18-.3 24.8z" />
          </svg>
          <span
            css={{
              display: 'flex',
              height: '100%',
              marginLeft: unit(1),
              alignItems: 'center',
            }}
          >
            Download image
          </span>
        </a>
      </span>
    );
  }
  return FooterCaption;
}

export default function LondonPhotosPage({ location, navigate }) {
  const params = qs.parse(location.search.slice(1));
  const currentImageSrc = params.image;

  let currentImageIndex = null;
  if (currentImageSrc) {
    currentImageIndex = getImageIndex(currentImageSrc);
  }

  const [currentImage, setCurrentImage] = useState(currentImageIndex || 0);
  const [viewerIsOpen, setViewerIsOpen] = useState(
    currentImageIndex !== null ? true : false
  );

  const openLightbox = useCallback(index => {
    updateUrl(index);
    setCurrentImage(index);
    setViewerIsOpen(true);
    trackEvent('Open lightbox', {
      image: PHOTOS[index].src,
      urlPath: location.pathname,
    });
  }, []);

  const handleViewChange = useCallback(index => {
    updateUrl(index);
  }, []);

  const updateUrl = useCallback(
    debounce(index => {
      if (index === null) {
        navigate(location.pathname);
        return;
      }

      trackEvent('View image', {
        image: PHOTOS[index].src,
        urlPath: location.pathname,
      });

      const params = {
        image: PHOTOS[index].src,
      };
      const url = `${location.pathname}?${qs.stringify(params)}`;
      navigate(url);
    }, 50),
    []
  );

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
    updateUrl(null);
    trackEvent('Close lightbox', {
      urlPath: location.pathname,
    });
  };

  const FooterCaptionComponent = useCallback(() => getFooterCaption(location), [
    location,
  ])();

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="Wedding photos"
      />
      <Global
        styles={css`
          body {
            overflow-x: hidden;
            font-size: 18px;
            font-family: 'Helvetica Neue', Helvetica, sans-serif;
          }

          ${BREAKPOINTS.normal} {
            p {
              font-size: 20px;
            }
          }

          .blur-up-img.ls-blur-up-is-loading,
          .blur-up-img.lazyload:not([src]) {
            visibility: hidden;
          }

          .ls-blur-up-img,
          .blur-up-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;

            object-fit: cover;
          }

          .ls-blur-up-img {
            filter: blur(10px);
            opacity: 1;
            transition: opacity 1000ms, filter 1500ms;
          }

          .ls-blur-up-img.ls-inview.ls-original-loaded {
            opacity: 0;
            filter: blur(5px);
          }
        `}
      />

      <Background
        src={`${IMGIX_URL}20190803-JK&Lisa-23.jpg`}
        css={{
          width: '100vw',
          height: 'calc(100vh - 200px)',
          [BREAKPOINTS.normal]: {
            height: '60vh',
          },
        }}
      >
        <div
          css={{
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            height: '100%',
            backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))',
          }}
        >
          <h1>3rd August 2019</h1>
          <h2 css={{ position: 'relative', top: -unit(2), fontSize: '3.4rem' }}>
            Civil Ceremony
          </h2>
        </div>
      </Background>

      <div
        className="container"
        css={{ [BREAKPOINTS.normal]: { width: '50%' } }}
      >
        <div
          css={{
            minHeight: '100vh',
            marginTop: unit(4),
            [BREAKPOINTS.normal]: {
              marginTop: unit(6),
            },
          }}
        >
          <Gallery
            photos={PHOTOS.map(photo => ({ ...photo }))}
            renderImage={({ photo, margin, index }) => (
              <a
                href="#"
                css={{
                  display: 'flex',
                  margin,
                  position: 'relative',
                  height: photo.height,
                  width: photo.width,
                }}
                onClick={event => {
                  event.preventDefault();
                  openLightbox(index);
                }}
                key={photo.src}
              >
                <Imgix
                  className="lazyload blur-up-img"
                  src={`${IMGIX_URL}${photo.src}`}
                  width={photo.width}
                  height={photo.height}
                  attributeConfig={{
                    src: 'data-src',
                    srcSet: 'data-srcset',
                    sizes: 'data-sizes',
                  }}
                  htmlAttributes={{
                    'data-lowsrc': `${IMGIX_URL}${photo.src}?auto=format&fit=crop&h=${photo.height}&w=${photo.width}&px=16&blur=300`,
                  }}
                />
              </a>
            )}
          />
        </div>
        <Section
          css={{
            textAlign: 'center',
            fontSize: 20,
            marginBottom: 60,
          }}
        >
          <SectionHeader css={{ marginBottom: 60 }} />
          <div>🐼 ❤️ 🐷</div>
        </Section>
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              hideControlsWhenIdle={50000}
              styles={{
                navigationPrev: base => ({
                  ...base,
                  padding: 0,
                }),
                navigationNext: base => ({
                  ...base,
                  padding: 0,
                }),
                footer: base => ({
                  ...base,
                  alignItems: 'center',
                }),
              }}
              currentIndex={currentImage}
              views={PHOTOS.map(photo => ({
                ...photo,
              }))}
              trackProps={{
                onViewChange: handleViewChange,
              }}
              components={{
                View: PhotoViewer,
                FooterCaption: FooterCaptionComponent,
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
}
